import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

type WrapperType = {
  disabled?: boolean;
  bold?: boolean;
};

export const Wrapper = styled.div<WrapperType>`
  font: normal normal 0.875rem/1.5rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.dark1};
  ${({ disabled, bold, theme }) => css`
    ${disabled &&
    css`
      color: ${theme.color.dark2};
    `}

    ${bold &&
    css`
      font-weight: bold;
    `}
  `}
`;
