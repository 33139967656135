import { useCallback, useEffect, useMemo } from 'react';
import { FieldRenderProps, useField } from 'react-final-form';
import { useSelector } from 'react-redux';

import { useFormErrorsActions } from '../../../../../../../styledComponents/FormErrorProvider/FormErrorProvider.hooks';
import { useActions } from '../../../../../../../hooks/useActions';
import { useRouteParams } from '../../../../../../../hooks/useRouteParams';
import { IApiValidationError } from '../../../../../../../models/ApiValidationError';

import { ICreateSecondaryMemberModel } from '../../../../../../../models/interfaces/Membership/ICreateSecondaryMemberModel';
import { MembershipType } from '../../../../../../../models/MembershipType';
import { IOrganizationSubscriptionPlanModel } from '../../../../../../../models/OrganizationSubscriptionPlanModel';
import { clearAAMembershipDates } from '../../../../../../../store/redux-slices/membership/airAmbulance';
import { aaMembershipDatesSelector } from '../../../../../../../store/redux-slices/membership/airAmbulance/selectors';
import { updateProcessState } from '../../../../../../../store/redux-slices/processes';
import { MEMBERSHIP_PROMO_CODE_LOADING } from '../../../../../../../store/redux-slices/processes/constants';
import {
  loadNewAAMembershipDates,
  loadRetailAAMembershipSummary,
} from '../../../../../../../store/saga-slices/membership/airAmbulance';
import {
  ILoadNewAAMembershipDatesPayload,
  ILoadRetailAAMembershipSummaryPayload,
} from '../../../../../../../store/saga-slices/membership/airAmbulance/models';
import { searchAAPromoCodesInfo } from '../../../../../../../store/saga-slices/optionsSearch/airAmbulance/api';
import { getRegularAAPlansQuery } from '../../../../../../../store/saga-slices/plans/airAmbulance/api';
import {
  dateIsValid,
  parseDate,
  parseExpirationDateOneMonth,
  parseExpirationDateTwoWeeks,
} from '../../../../../../../utils/dateHelpers';
import { useApiWarning } from '../../../../../../../styledComponents/WarningsConfirmation/warningsConfirmation.hooks';
import { MembershipRetailDetailsFormFields } from './aaMembershipRetailsDetailsForm.models';
import { MembershipFamilyMembersFormFields } from '../../MembershipCommon/MembershipFamilyMembersForm/membershipFamilyMembers.models';
import { useCancellationToken } from '../../../../../../../hooks/useTokenCancellation';
import { useRunOnce } from '../../../../../../../hooks/useRunOnce';
import { primaryMemberSelector } from '../../../../../../../store/redux-slices/membership/common/selectors';
import { AirAmbulanceMembershipOptions } from '../../../../../../../models/enums/AirAmbulanceMembershipOptions';
import { clearMembershipSummary } from '../../../../../../../store/redux-slices/membership/common';
import { useComponentIsMounted } from '../../../../../../../hooks/useComponentIsMounted';
import { MembershipPlanPeriodsType } from '../../../../../../../models/enums/MembershipPlanPeriodsType';
import {
  isAutoRenewal,
  mapToTermType,
} from '../../addMemberMembershipPage.utils';
import { getDatesForNewAAMemberships } from '../../../../../../../store/saga-slices/membership/airAmbulance/api';

export function useMembershipRetailDetails() {
  const { clearErrors, addError } = useFormErrorsActions();
  const { setRatifications } = useApiWarning();

  const cancellationToken = useCancellationToken();

  const UpdateProcessState = useActions(updateProcessState);
  const LoadRetailMembershipSummary = useActions(loadRetailAAMembershipSummary);
  const LoadNewMembershipDates = useActions(loadNewAAMembershipDates);
  const ClearMembershipDates = useActions(clearAAMembershipDates);
  const ClearMembershipSummary = useActions(clearMembershipSummary);
  const isMounted = useComponentIsMounted();

  const { membershipId } = useRouteParams();

  const isRenewMembership = useMemo(() => !!membershipId, [membershipId]);

  const primaryMember = useSelector(primaryMemberSelector);
  const initDates = useSelector(aaMembershipDatesSelector);

  const {
    input: { value: promoCode, onChange: promoCodeChange },
  }: FieldRenderProps<string> = useField(
    MembershipRetailDetailsFormFields.PromoCode,
  );

  const {
    input: { onChange: renewedMembershipIdChange },
  }: FieldRenderProps<string> = useField(
    MembershipRetailDetailsFormFields.RenewedMembershipId,
  );

  const {
    input: { value: selectedPlan, onChange: selectedPlanChange },
  }: FieldRenderProps<IOrganizationSubscriptionPlanModel> = useField(
    MembershipRetailDetailsFormFields.MembershipPlanId,
  );

  const {
    input: { value: effectiveDate, onChange: effectiveDateChange },
  }: FieldRenderProps<string> = useField(
    MembershipRetailDetailsFormFields.EffectiveDate,
  );

  const {
    input: { value: expirationDate, onChange: expirationDateChange },
  }: FieldRenderProps<string> = useField(
    MembershipRetailDetailsFormFields.ExpirationDate,
  );
  const {
    input: { onChange: secondaryMembersChange },
  }: FieldRenderProps<ICreateSecondaryMemberModel[]> = useField(
    MembershipFamilyMembersFormFields.SecondaryMembers,
  );
  const {
    input: { value: autoRenewal, onChange: autoRenewalChange },
  }: FieldRenderProps<boolean> = useField(
    AirAmbulanceMembershipOptions.AutoRenewal,
  );

  useEffect(() => {
    renewedMembershipIdChange(membershipId);
  }, [membershipId, renewedMembershipIdChange]);

  const clearFields = useCallback(() => {
    if (isMounted()) {
      return;
    }
    promoCodeChange(null);
    selectedPlanChange(null);
    effectiveDateChange(null);
    expirationDateChange(null);
    secondaryMembersChange(null);
  }, [
    effectiveDateChange,
    expirationDateChange,
    isMounted,
    promoCodeChange,
    secondaryMembersChange,
    selectedPlanChange,
  ]);

  const resetData = useCallback(() => {
    clearErrors();
    setTimeout(() => clearFields(), 0);
    setRatifications([]);
    ClearMembershipDates();
  }, [clearErrors, clearFields, setRatifications, ClearMembershipDates]);

  const {
    input: {
      value: { termType },
    },
  }: {
    input: {
      value: { termType: MembershipPlanPeriodsType };
    };
  } = useField(MembershipRetailDetailsFormFields.MembershipPlanId);
  useEffect(() => {
    const payload: ILoadNewAAMembershipDatesPayload = {
      cancellationToken,
      termType: mapToTermType(termType),
    };

    LoadNewMembershipDates(payload);
  }, [LoadNewMembershipDates, cancellationToken, termType]);

  useRunOnce(() => {
    if (effectiveDate || expirationDate) {
      switch (termType) {
        case MembershipPlanPeriodsType.Days_14:
          expirationDateChange(parseExpirationDateTwoWeeks(effectiveDate));
          break;
        case MembershipPlanPeriodsType.Days_30:
          expirationDateChange(parseExpirationDateOneMonth(effectiveDate));
          break;
        default:
          break;
      }
      return;
    }

    effectiveDateChange(initDates.effectiveDate);
    expirationDateChange(initDates.expirationDate);
  }, !!initDates);

  useEffect(() => () => resetData(), [resetData]);

  useEffect(() => {
    switch (termType) {
      case MembershipPlanPeriodsType.Days_14:
        autoRenewalChange(false);
        expirationDateChange(parseExpirationDateTwoWeeks(effectiveDate));
        break;
      case MembershipPlanPeriodsType.Days_30:
        autoRenewalChange(false);
        expirationDateChange(parseExpirationDateOneMonth(effectiveDate));
        break;
      default:
        autoRenewalChange(true);
        break;
    }
  }, [autoRenewalChange, effectiveDate, expirationDateChange, termType]);

  const isDateChangeable = useMemo(() => {
    switch (termType) {
      case MembershipPlanPeriodsType.Days_14:
      case MembershipPlanPeriodsType.Days_30:
        return true;
      default:
        return false;
    }
  }, [termType]);
  useEffect(() => {
    if (!selectedPlan || !effectiveDate) {
      ClearMembershipSummary();
      return;
    }
    const payload: ILoadRetailAAMembershipSummaryPayload = {
      membershipPlanId: selectedPlan.membershipPlanId,
      promoCode,
      effectiveDate,
      autoRenewal: isAutoRenewal(termType) ? autoRenewal : false,
      cancellationToken,
      error: () => ClearMembershipSummary(),
    };
    LoadRetailMembershipSummary(payload);
  }, [
    ClearMembershipSummary,
    LoadRetailMembershipSummary,
    autoRenewal,
    cancellationToken,
    effectiveDate,
    promoCode,
    selectedPlan,
    termType,
  ]);

  const addPromoCodeError = useCallback(
    (promoCodeValue: string, errorCode: string) => {
      const error: IApiValidationError = {
        PropertyName: MembershipRetailDetailsFormFields.PromoCode,

        ErrorCode: errorCode,
        InternalError: true,
      };

      addError(error, promoCodeValue);
    },
    [addError],
  );

  const checkPromoCode = useCallback(() => {
    if (!promoCode) {
      return;
    }
    UpdateProcessState(MEMBERSHIP_PROMO_CODE_LOADING);
    searchAAPromoCodesInfo(promoCode, cancellationToken)
      .then((promoCodeInfo) => {
        if (
          promoCodeInfo.data.membershipType.toLocaleLowerCase() !==
          MembershipType.Retail.toLocaleLowerCase()
        ) {
          addPromoCodeError(promoCode, 'promo-code-for-organization');
        }
      })
      .catch(() => {
        addPromoCodeError(promoCode, 'promo-code-not-found');
      })
      .finally(() => UpdateProcessState(MEMBERSHIP_PROMO_CODE_LOADING));
  }, [UpdateProcessState, addPromoCodeError, cancellationToken, promoCode]);

  useEffect(() => {
    if (!promoCode) {
      return;
    }
    checkPromoCode();
  }, [checkPromoCode, promoCode]);

  const plansPromiseOptions = useCallback(() => {
    if (!primaryMember?.dateOfBirth || !dateIsValid(effectiveDate)) {
      return Promise.resolve([]);
    }

    return getRegularAAPlansQuery(
      primaryMember?.dateOfBirth,
      effectiveDate,
      cancellationToken,
    ).then(({ data }) =>
      data.plans.map((plan) => ({
        label: plan.membershipPlanName,
        value: plan,
      })),
    );
  }, [effectiveDate, primaryMember?.dateOfBirth, cancellationToken]);
  return {
    plansPromiseOptions,
    checkPromoCode,
    isRenewMembership,
    membershipId,
    isDateChangeable,
  };
}
